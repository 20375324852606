.marg-arriba {
  margin-top: 70px;
}

.h1-diseno {
  font-size: 12vh;
  font-family: "Rajdhani", sans-serif;
  color: rgb(25, 175, 255) !important;
  -webkit-text-stroke: 0.2px black;
  margin-left: 2vh;
  margin-top: 70px;
}
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}

.centered h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.centered h2 {
  font-size: 1.5rem;
  color: #666;
  margin-top: 0;
}
@media (max-width: 600px) {
  .h1-diseno {
    font-size: 8.5vh;
  }
}
