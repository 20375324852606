@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Rajdhani:wght@700&display=swap");

html {
  scroll-behavior: smooth;
}
.body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
}
.sticky-nav {
  position: fixed;
  top: 0;
}
.sticky-foot {
  position: fixed;
  bottom: 0;
}
.ancho-total {
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
}
.padding-logo {
  padding-left: 0.5vh;
  margin-top: -0.25rem;
}
.disable {
  pointer-events: none;
  cursor: default;
}
h1 {
  font-family: "Rajdhani", sans-serif;
}
h2 {
  font-size: 4vh;
  font-family: "Rajdhani", sans-serif;
}
.marg-der {
  margin-right: -0.8vh;
  margin-top: -0.8vh;
}

.deco-no {
  text-decoration: none;
  color: black;
}

.text-sm {
  font-size: 1.8vh;
}
.tamano5 {
  font-size: 9vh !important;
}

@media screen and (max-width: 768px) {
  .tamano5 {
    font-size: 7vh !important;
  }
}

@media screen and (max-width: 600px) {
  .tamano5 {
    font-size: 5vh !important;
  }
}

@media screen and (max-width: 400px) {
  .tamano5 {
    font-size: 4vh !important;
  }
}