.marg-arriba {
  margin-top: 70px;
}
.h1-arq {
  font-size: 12vh;
  font-family: "Rajdhani", sans-serif;
  color: rgb(255, 140, 0) !important;
  -webkit-text-stroke: 0.2px black;
}
.h2-arq {
  font-size: 6vh;
  font-family: "Rajdhani", sans-serif;
}

@media (max-width: 1000px) {
  .h1-arq {
    font-size: 10vh;
  }
}

@media (max-width: 500px) {
  .h1-arq {
    font-size: 7.5vh;
  }
}

.h-arq {
  font-family: "Rajdhani", sans-serif;
}


.derecha {
  text-align: right;
}

.enlace-arq {
  color: #000;
}
.enlace-arq:hover {
  color: #505050;
}

.secundario {
  color: #505050;
  font-size: small;
  margin-right: 1vh ;
}

.maximo {
  min-height: 100%;
}
