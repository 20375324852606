.padding-lados {
  padding-left: 1vh;
  padding-right: 1vh;
}
.txt-enlace {
  font-size: 18px;
  font-weight: 500;
}
.borde-2 {
  border-bottom: 1px solid #282c34;
}
.txt-white {
  color: white;
}
.txt-black {
  color: black;
}
#bg-naranja {
  background-color: #ff8c00 !important;
}
#bg-azul {
  background-color: rgb(25, 175, 255) !important;
}
#bg-fluor {
  background-color: #18d794 !important;
}
.max-h {
  height: max-content;
  height: auto;
}
.tam-fijo {
  height: 40px;
}

.marg-der {
  margin-right: 2vh;
}
