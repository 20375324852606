.flip-card-outer {
  width: 325px;
  height: 200px;
  margin: 0px;
  padding: 0px;
  position: relative;
  display: block;
  perspective: 1200px;

  &.focus-trigger:focus {
    outline: 5px solid rgb(44, 44, 44);
    outline-offset: 5px;
  }

  .flip-card-inner {
    transform-style: preserve-3d;
    transition: .5s linear .1s;
    position: relative;
    width: 325px;
    height: 200px;

    &.hover-trigger:hover {
      transform: rotateY(180deg);
    }

    &.showBack {
      transform: rotateY(180deg);
    }
  
    .card {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  
      &.front {
        transform: rotateY(0);
        background-color: #141414;
        color: #fff;
      }
  
      &.back {
        transform: rotateY(180deg);
        background-color: #141414;
        color: #fff;
      }
    }
  }
}
.marg-top {
  margin-top: 15vh;
  margin-bottom: 5vh;
}

$media: 992px;
@media (max-width: $media) {
  .marg-top {
    margin-top: 6vh;
    margin-bottom: 4vh;
  }
}