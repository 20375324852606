.boton-chico {
  height: 30px;
}
.centrado {
  justify-content: center;
  vertical-align: middle;
}
.marg-top {
  margin-top: 25px;
}
.menos-marg-top {
  margin-top: -4px;
}
.txt-white {
  color: white;
}
.txt-chico {
  margin-top: 25px;
  font-size: 8.5px;
}
.txt-gris {
  color: rgb(193, 189, 189);
}
.margenes-30 {
  margin: 30px;
  margin-bottom: 0;
}
.sin-marg {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
.footer {
  max-width: 100% !important;
  width: 100% !important;
}
.gris-cursiva {
  color: rgb(193, 189, 189);
  font-style: italic;
}
.marg-izq {
  margin-left: 5px;
}
.mt {
  margin-top: 3vh;
}
.mr {
  margin-right: 2.5vh;
}
