.hacia-arriba {
  margin-top: -15vh;
  padding-top: -15vh;
}
.h1-inicio {
  font-size: 18vh;
  font-family: "Rajdhani", sans-serif;
}
.h1-inicio2 {
  font-size: 12vh;
  font-family: "Rajdhani", sans-serif;
}
.h3-inicio {
  font-size: 5vh;
  font-family: "Rajdhani", sans-serif;
}
.marg-lados {
  margin-left: 16px;
  margin-right: 16px;
}
@media (max-width: 500px) {
  .marg-lados {
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
}
@media (max-width: 1200px) {
  .h1-inicio {
    font-size: 14vh !important;
  }
}
@media (max-width: 860px) {
  .h1-inicio {
    font-size: 10vh !important;
  }
}
@media (max-width: 630px) {
  .h1-inicio {
    font-size: 7.5vh !important;
  }
}
@media (max-width: 380px) {
  .h1-inicio {
    font-size: 4vh !important;
  }
}

.marg-inf {
  margin-bottom: 50px;
}
.marg-sup {
  margin-top: 40px;
}
.marg-sup-2 {
  margin-top: 25px;
}

@media (max-width: 992px) {
  .marg-sup {
    margin-top: 20px;
  }
}
.bg-inicio {
  background: url(../../img/todos/bgInicio.png) fixed;
  background-size: cover;
}

.marg-abj {
  margin-bottom: 15px;
}
.marg-abj-btn {
  margin-bottom: 30px;
}

.centrado {
  text-align: center;
}
.al-final {
  text-align: end;
}
.marg-ab-card {
  margin-bottom: 25px;
}
.marg-ab-card2 {
  margin-bottom: 40px;
}
@media (max-width: 766px) {
  .tamano7 {
    height: 14vh !important;
    width: 14vh !important;
  }
}
@media (max-width: 500px) {
  .tamano7 {
    height: 1vh !important;
    width: 1vh !important;
  }
  .h1-inicio {
    font-size: 6vh !important;
  }
}
@media (max-width: 1600px) {
  .tamano7 {
    height: 19vh !important;
    width: 19vh !important;
  }
}
@media (max-width: 1280px) {
  .tamano7 {
    height: 16vh !important;
    width: 16vh !important;
  }
}
@media (max-width: 1400px) {
  .tamano4 {
    font-size: 16vh !important;
  }
  .tamano7 {
    height: 18vh !important;
    width: 18vh !important;
  }
  .tamano4 {
    font-size: 10vh !important;
  }
}
@media (max-width: 1150px) {
  .tamano7 {
    height: 15vh !important;
    width: 15vh !important;
  }
  .h3-inicio {
    font-size: 4vh !important;
  }
}

@media (max-width: 1280px) {
  .tamano4 {
    font-size: 12vh !important;
  }
}
@media (max-width: 1080px) {
  .tamano7 {
    height: 13vh !important;
    width: 13vh !important;
  }
}
@media (max-width: 991px) {
  .tamano7 {
    height: 15vh !important;
    width: 15vh !important;
  }
}
@media (max-width: 870px) {
  .tamano7 {
    height: 13vh !important;
    width: 13vh !important;
  }
}
@media (max-width: 490px) {
  .tamano4 {
    font-size: 6vh !important;
  }
}
@media (max-width: 767px) {
  .tamano7 {
    height: 16vh !important;
    width: 16vh !important;
  }
}
.tamano7 {
  border: 1.5px solid rgb(189, 189, 189);
  height: 22vh;
  width: 22vh;
  margin-top: -2vh;
  padding-top: -1vh;
  margin-right: -2vh;
}
ta .poco-arr {
  margin-top: -0.5vh;
}
.hover:hover {
  color: rgb(139, 139, 139);
}
.just {
  text-align: justify;
}

.justify {
  text-align: justify;
}
em {
  font-style: normal;
  font-weight: 250;
  color: rgb(139, 139, 139);
}

.marg-abj-2 {
  margin-bottom: 6px;
}
.marg-abj-final {
  margin-bottom: 40px;
}

@media (max-width: 870px) {
  .sm-txt {
    font-size: 2.5vh !important;
  }
}
input[type="checkbox"] {
  accent-color: rgb(29, 29, 29) !important;
}
.marg-izq {
  margin-left: 10px;
}

.mb-neg {
  margin-bottom: -5px;
}

.hover-negro:focus {
  border-color: rgb(44, 44, 44);
  box-shadow: 0 0 5px rgb(44, 44, 44);
}
