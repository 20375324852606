.ancho-total {
  width: 100%;
}
.naranja {
  color: rgb(255, 140, 0);
  text-shadow: 1px 1px 5px #2c2c2c;
}
.fluor {
  color: #18d794;
  text-shadow: 1px 1px 5px #2c2c2c;
}
.azul {
  color: rgb(25, 175, 255);
  text-shadow: 1px 1px 5px #2c2c2c;
}
.pos-arr-der {
  position: absolute;
  right: 3vh;
  top: 14vh;
}
.carousel-item {
  transition-duration: 1.4s !important;
}
.sind-pad {
  padding: 0px !important;
}

