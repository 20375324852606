.App {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
  width: 100%;
}
.margenes-0 {
  margin: 0;
  padding: 0;
}
.altura-450 {
  height: 450px;
}
.fit {
  object-fit: cover;
  height: 600px;
}
.txt-size {
  font-size: 12vw;
}
.txt-size2 {
  font-size: 6vw;
}
.pos-der {
  position: absolute;
  right: 0;
}
.borde-abj-20 {
  border-bottom: 2px solid black;
}
@media (max-width: 600px) {
  .tamano2 {
    font-size: 15vw;
  }
}
@media (max-width: 600px) {
  .tamano3 {
    font-size: 7vw;
  }
}
.marg-arr-abj {
  margin-top: 3px;
  margin-bottom: 3px;
}

.marg-lados {
  margin-left: 25px;
  margin-right: 25px;
}
.h1-inicio {
  font-size: 16vh;
  font-family: "Rajdhani", sans-serif;
}
.h2-inicio {
  margin-top: -4vh;
  font-size: 7vh;
  font-family: "Rajdhani", sans-serif;
}
.RowP {
  font-size: 3vh;
  margin-top: 1vh;
  font-family: Verdana, sans-serif;
  font-style: italic;
}
@media (max-width: 1200px) {
  .tamano4 {
    font-size: 12vw;
  }
}
@media (max-width: 1200px) {
  .tamano5 {
    font-size: 5vw;
    margin-top: -2vh;
  }
}
@media (max-width: 650px) {
  .tamano6 {
    font-size: 3vw;
    margin-top: 0vh;
  }
}
.ancho-total {
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
}
.sin-pad {
  padding: 0 !important;
}
.carousel-inner {
  width: 100%;
  padding: 0%;
  margin: 0%;
}