.marg-arriba-sob {
  margin-top: 70px;
}

.tamano8 {
  border: 1.5px solid rgb(189, 189, 189);
  height: 25vh;
  width: 25vh;
}

.der-arr-sob {
  position: re;
  right: 45px;
  top: 90px;
}
.marg-izq-sob {
  margin-left: 3vh;
}
@media (max-width: 969px) {
  .marg-izq-sob {
    margin-left: 0vh;
  }
}

.marg-lados-sob {
  margin-left: 2vh;
  margin-right: 2vh;
}

.marg-l-neg {
  margin-left: -18vh;
}
.marg-l-neg2 {
  margin-left: -13vh;
}
.marg-logos {
  margin-left: -5vh;
}

@media (max-width: 1190px) {
  .marg-l-neg {
    margin-left: -14vh;
  }
  .marg-logos {
    margin-left: -2vh;
  }
}
.tamano9 {
  border: 1.5px solid rgb(189, 189, 189);
  height: 60vh;
  width: 60vh;
}

container h1 {
  font-size: 12vh;
  font-family: "Rajdhani", sans-serif;
}

.h1-sobre {
  font-size: 12vh;
  font-family: "Rajdhani", sans-serif;
  -webkit-text-stroke: 0.2px black;
  margin-left: 2vh;
  margin-top: 70px;
}
.tamano10 {
  font-size: 9vh !important;
}

.marg-ab-card3 {
  margin-bottom: 8vh;
}

.tamanoTitulo {
  font-size: 3.5vh;
}

.tamanoCargo {
  font-size: 3.2vh;
}

.promedio {
  font-size: 2vh;
  margin-top: -1.5vh;
  font-style: oblique;
}
.creditos {
  font-size: 1.8vh;
  margin-top: -1.5vh;
  font-style: oblique;
}

.sub {
  font-size: 1.7vh;
  margin-top: -1vh;
  text-align: justify;
}
.sub1 {
  font-size: 1.3vh;
  text-align: end;
  margin-top: 1vh;
  margin-right: 2vh;
}

.just {
  text-align: justify;
}

#vermas {
  display: none;
}
.chiquito {
  margin-top: 0.5vh;
  font-size: 1.1vh;
}

.enlace {
  color: #000;
  font-size: 1.8vh;
}

.enlace:hover {
  color: #282828;
}

.margen {
  margin-top: 1.5vh;
}
.subtitulo {
  font-size: 7.1vh;
  font-weight: 800;
}

.hastag {
  font-size: 5.4vh;
}
.arq {
  color: rgb(255, 140, 0) !important;
  -webkit-text-stroke: 0.2px black;
}
.des {
  color: rgb(25, 175, 255) !important;
  -webkit-text-stroke: 0.2px black;
}
.prog {
  color: #18d794 !important;
  -webkit-text-stroke: 0.2px black;
}
.hastag2 {
  font-size: 3vh;
  font-family: "Rajdhani", sans-serif;
}
.m-neg-topbot {
  margin-top: -3.5vh;
  margin-bottom: -1.5vh;
}
.m-neg-topbot2 {
  margin-top: -3.5vh;
  margin-bottom: 5vh;
}
.centrado {
  margin-top: 4vh;
}
.no-m-top {
  margin-top: 0vh !important;
  color: rgb(30, 30, 30);
}
.solo-subrayado {
  color: rgb(30, 30, 30);
  text-decoration: underline;
}

.certificado {
  font-size: 2.4vh;
  margin-top: 1vh;
  font-weight: 250;
}
